<template>
  <div class="dashboard__container" v-if="user">
    <div class="dashboard__container--body" v-if="user">
      <!-- <div class="dashboard__container--body--col">
        <UserEvents :events="userEvents" />
      </div>
      <div class="dashboard__container--body--col"> -->
        <UserApplications :assignments="userApplications" />
      <!-- </div> -->
    </div>
<Loader v-if="(userApplications && userApplications.length == 0)" />

    </div>

</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserApplications from '@/components/Profile/UserApplications.vue'
// import UserEvents from '@/components/Profile/UserEvents.vue'

export default {
  props: ['user'],
  name: 'userApplications',
  computed: {
    ...mapState(['currentUser', 'userProfile', 'userApplications']),
  },
  created() {
    this.$store.dispatch('getUserApplications', this.$route.params.id)
    // this.$store.dispatch('getUserEvents', this.$route.params.id)
  },
  components: {
    Loader,
    UserApplications,
    // UserEvents
  },
}

</script>